<template>
  <header class="masthead masthead-custom">
      <div class="container h-100" style="height:100px;">
          <div class="row justify-content-left h-100" style="height:80px;">
              <div class="col-12 col-lg-12 mt-auto" style="margin:0px;margin-top:0px;margin-bottom:0px;height:0px;max-width:100%;">
                  <div class="mx-auto header-content">
                      <h1 class="mb-5 text-black">Subscriptions</h1>
                  </div>
                  <h3 class="text-black">Manage your Services</h3>
              </div>
          </div>
      </div>
  </header>

  <section id="subscriptions" class="features" style="padding-top:20px; padding-bottom:10px;">
    <div class="container">
      <div class="card border-grey">
        <div class="card-body" v-if="authenticated">
          <h4 class="card-title">Subscribed Services</h4>
          <BTable show-empty
                              responsive
                              striped
                              hover
                              :items="services"
                              :fields="fields"
                              :current-page="currentPage"
                              :per-page="perPage"
                            >
              <template v-slot:cell(service)="row">{{ row.value }}</template>
              <template v-slot:cell(description)="row">
                {{ row.value.includes('http') ? row.value : row.value }}
              </template>
              <template v-slot:cell(license)="row">{{ row.value==undefined ? 'N/A' : row.value }}</template>
              <template v-slot:cell(plan)="row">{{ row.value==undefined ? 'N/A' : row.value }}</template>
              <template v-slot:cell(status)="row">{{ row.value }}</template>
              <template v-slot:cell(id)="services">
                <BButton size="sm" @click.stop="selectSubscription(services.item); show = !show" class="mr-1">
                  {{ 'Show' }}
                </BButton>
              </template>
              <!--template v-slot:cell(actions)="services">
                <BButton size="sm" @click.stop="selectSubscription(services.item); key = !key" class="mr-1">
                  {{ 'Key' }}
                </BButton>
              </template-->
              <template v-slot:cell(actions)="services">
                <BButton v-if="services.item" size="sm" @click.stop="selectSubscription(services.item); key = !key" :disabled="services.item.state!=='active' || !canSubmit" class="mr-1">
                  {{ services.item.state==='active' ? services.item.apim.state==='active' ? 'Key' : 'Re-generate' : 'Re-activate' }}
                </BButton>
              </template>
          </BTable>
          <p class="text-sm-left">
            Application / Service subscribers need to enter their <i>subscription key</i> in the appropriate ApiKey field within the settings dialog of the app resp. the plugin.
          </p>
          <p class="text-sm-left">
            API Subscribers need to use the <i>API Management subscription key</i> in one of two ways:
            <ol>
              <li>Add the <code>Ocp-Apim-Subscription-Key</code> HTTP header to the request, passing the value of a valid subscription key.</li>
              <li>Include the <code>subscription-key</code> query parameter and a valid value in the URL. The query parameter is checked only if the header isn't present.</li>
            </ol>
          </p>
        </div>
      </div>
    </div>
  </section>

  <div v-if="show">
    <BModal id="subDetailsModal"
              ref="modalShow"
              title="Service Details"
              header-bg-variant="secondary"
              header-text-variant="light"
              footer-bg-variant="light"
              footer-text-variant="dark"
              hide-backdrop
              ok-only
              v-model="show">
      <BContainer fluid>
        <BFormRow>
          <BCol><em><strong>ReadMe</strong></em></BCol><BCol>Lorem ipsum ...</BCol>
        </BFormRow>
      </BContainer>
    </BModal>
  </div>

  <div v-if="key">
    <BModal id="subKeyModal"
              ref="modalKey"
              title="API Management"
              header-bg-variant="secondary"
              header-text-variant="light"
              footer-bg-variant="light"
              footer-text-variant="dark"
              hide-backdrop
              ok-only
              v-model="key">
      <BContainer fluid>
        <BFormRow>
          <BCol><em><strong>Subscription Key</strong></em></BCol><BCol>{{ this.subscription ? this.subscription.apim.key  : 'N/A' }}</BCol>
        </BFormRow>
      </BContainer>
    </BModal>
  </div>

  <!--div>
      <p class="text-black">{{ this.authenticated ? "Authenticated" : "Require Auth" }}</p>
      <p class="text-black">{{ this.user===null ? "No User Data" : this.user.username }}</p>
      <p class="text-black">{{ this.user===null ? "No Subscriber Data" : this.user.subscriberId }}</p>
  </div-->
</template>

<script>
import { ref, inject } from 'vue'
import { useSubscriberStore } from '@/stores/common'
import { useServicesStore } from '@/stores/subscriptions'
import { useToast } from 'vue-toastification'
import { mapState, mapActions } from 'pinia'

export default {
  setup() {
    const show = ref(false)
    const key = ref(false)
    const toast = useToast();
    const loader = inject('$loading')
    const fullPage = ref(false)
    return { show, key, toast, fullPage, loader }
  },
  name: 'mySubscriptions',
  props: { 
    authenticated: Boolean,
    user: Object,
    notify: Object
   },
  data() {
    return {
      message: '',
      html: '<i class="fas fa-cog fa-spin fa-3x fa-fw"></i>',
      fields: [
        { key: 'service', label: 'Service' },
        { key: 'description', label: 'Description' },
        { key: 'id', label: 'Details', sortable: true },
        { key: 'license', label: 'License' },
        { key: 'plan', label: 'Plan' },
        { key: 'state', label: 'State', sortable: true },
        { key: 'actions', label: 'Subscription' }
      ],
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15]
    };
  },
  computed: {
    ...mapState(useSubscriberStore,['subscriptions']),
    ...mapState(useServicesStore,['services','subscription']),
    canSubmit() {
      return true || (this.authenticated && this.user.subscriberId!=='')
    }
  },
  methods: {
    ...mapActions(useServicesStore,['setServices','setSubscription','getServices','unsubscribeSelected']),
    retrieveServices() {
      this.getServices(this.user ? this.user.localAccountId : undefined, this.subscriptions, this.loader)
      .catch(err => {
          this.toast.error(
            err.response ? err.response : err.message ? err.message : err,
            this.notify.error
          );
      })
    },
    selectSubscription(item) {
      this.setSubscription(item);
    }
  },
  mounted() {
    this.retrieveServices()
  }
}  
</script>