<template>
    <div>
    <!-- Masthead-->
    <header class="masthead">
        <div class="container position-relative">
            <div class="row justify-content-center">
                <div class="col-xl-6">
                    <div class="text-center text-white">
                        <h1 class="mb-5">Be Inspired!<br>Easily touch on the latest innovations<br>and integrate into your own products!</h1>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!-- Icons Grid-->
    <section class="features-icons bg-light text-center">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="features-icons-item mx-auto mb-0 mb-lg-3">
                        <div class="features-icons-icon d-flex"><i class="bi-building-fill-lock  m-auto text-primary"></i></div>
                        <h3>Highly Secure</h3>
                        <p class="lead mb-0">Access to APIs secured with personalized keys, authenticated via MFA-secured directories!</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                        <div class="features-icons-icon d-flex"><i class="bi-cloud m-auto text-primary"></i></div>
                        <h3>Cloud Native</h3>
                        <p class="lead mb-0">All Services natively-built and hosted in the Cloud, underlying Infrastructure provisioned as Code</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                        <div class="features-icons-icon d-flex"><i class="bi-layers m-auto text-primary"></i></div>
                        <h3>Flexible Architecture</h3>
                        <p class="lead mb-0">Featuring a modern micro-services-based architecture, extended with AI capabilities</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Image Showcases-->
    <section class="showcase">
        <div class="container-fluid p-0">
            <div class="row g-0">
                <!--div class="col-lg-6 order-lg-2 text-white showcase-img" style="background-image: url('../assets/img/bg-showcase-1.jpg')"></div-->
                <div class="col-lg-6 order-lg-2 my-auto">
                    <img src="@/assets/bg-generative-ai.jpg" class="img-fluid mx-auto d-block" alt="...">
                </div>
                <div class="col-lg-6 order-lg-1 my-auto showcase-text">
                    <h2>Generative AI</h2>
                    <p class="lead mb-0">Keep track of the latest innovations as we experiment leveraging generative AI. 
                        As an example we integrated OpenAI into our <code>countries</code> service for dynamic content and translations! 
                        <router-link to="/services/countries">Read more</router-link> ...</p>
                </div>
            </div>
            <div class="row g-0">
                <!--div class="col-lg-6 text-white showcase-img" style="background-image: url('assets/img/bg-showcase-2.jpg')"></div-->
                <div class="col-lg-6 my-auto">
                    <img src="@/assets/bg-environmental-sustainability.jpg" class="img-fluid mx-auto d-block" alt="...">
                </div>
                <div class="col-lg-6 my-auto showcase-text">
                    <h2>Environmentals</h2>
                    <p class="lead mb-0">Sustainability is turning into a key business success factor; organizations want to make sure they are continuously provided 
                        with appropriate signals eg. from environmental surroundings, weather, etc. to provide warnings and alerts to their operational personnel or even consumers!
                        <router-link to="/services/environmentals">Read more</router-link> ...</p>
                </div>
            </div>
        </div>
    </section>
    <section class="testimonials text-center bg-light">
        <div class="container-fluid p-0">
            <h2 class="mb-5">Client case study -- OpenSeaLog&trade;</h2>
            <div class="row g-0">
                <div class="col-lg-6 order-lg-1 my-auto testimonial-item">
                    <h4>Converged Maritime LogBooks</h4>
                    <p class="lead mb-0"><br><a href='#'>OpenSeaLog</a>&trade; is an easy to implement consumer-grade service for private boaters to keep track of nautical trips and automate their associated logbooks.</p>
                    <p class="lead mb-0"><br>It's moving from a traditionally paper-based format to mixing manual signals provided on a mobile device with IoT data gathered on board.</p>
                </div>
                <!--div class="col-lg-1 order-lg-2 my-auto">&nbsp;</div-->
                <div class="col-lg-6 order-lg-2 my-auto">
                    <img src="@/assets/OpenSeaLogTM.jpg" class="img-fluid mx-auto d-block rounded" alt="...">
                </div>
            </div>
        </div>
    </section>
    <!-- Testimonials-->
    <!--section class="testimonials text-center bg-light">
        <div class="container">
            <h2 class="mb-5">What people are saying...</h2>
            <div class="row">
                <div class="col-lg-4">
                    <div class="testimonial-item mx-auto mb-5 mb-lg-0">
                        <img class="img-fluid rounded-circle mb-3" src="@/assets/img/testimonials-1.jpg" alt="..." />
                        <h5>Margaret E.</h5>
                        <p class="font-weight-light mb-0">"This is fantastic! Thanks so much guys!"</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="testimonial-item mx-auto mb-5 mb-lg-0">
                        <img class="img-fluid rounded-circle mb-3" src="@/assets/img/testimonials-2.jpg" alt="..." />
                        <h5>Fred S.</h5>
                        <p class="font-weight-light mb-0">"Bootstrap is amazing. I've been using it to create lots of super nice landing pages."</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="testimonial-item mx-auto mb-5 mb-lg-0">
                        <img class="img-fluid rounded-circle mb-3" src="@/assets/img/testimonials-3.jpg" alt="..." />
                        <h5>Sarah W.</h5>
                        <p class="font-weight-light mb-0">"Thanks so much for making these free resources available to us!"</p>
                    </div>
                </div>
            </div>
        </div>
    </section-->
    <!-- Call to Action-->
    <section class="call-to-action text-white text-center" id="cta" v-show=!authenticated>
        <div class="container position-relative">
            <div class="row justify-content-center">
                <div class="col-xl-6">
                    <h2 class="mb-4">Need to know more?<br>Provide us with your email and we'll get back to you!</h2>
                    <form class="row g-3">
                        <div class="col-md-8">
                            <div class="input-group has-validation">
                                <span class="input-group-text" id="inputGroupPrepend3">@</span>
                                <input type="email" class="form-control is-invalid" placeholder="Email Address" id="validationEMail" aria-describedby="inputGroupPrepend3 validationEMailFeedback" required>
                                <div class="invalid-feedback text-white" data-sb-feedback="emailAddressBelow:required">Email is required.</div>
                                <div class="invalid-feedback text-white" data-sb-feedback="emailAddressBelow:email">Email is not valid.</div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-check">
                            <input class="form-check-input is-invalid" type="checkbox" value="" id="invalidCheck3" aria-describedby="invalidCheck3Feedback" required>
                            <label class="form-check-label" for="invalidCheck3">
                                Agree to terms and conditions
                            </label>
                            <div id="invalidCheck3Feedback" class="invalid-feedback">
                                You must agree to our T&C to allow us contacting you.
                            </div>
                        </div></div>
                        <div class="col-md-2">
                            <button class="btn btn-primary" type="submit">Notify me!</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>

<script>
// @ is an alias to /src
export default {
name: 'homePage',
props: { authenticated: Boolean },
components: {}
};
</script>
  
<style scoped>

/*  Features */
.features-icons {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.features-icons .features-icons-item {
  max-width: 20rem;
}
.features-icons .features-icons-item .features-icons-icon {
  height: 7rem;
}
.features-icons .features-icons-item .features-icons-icon i {
  font-size: 4.5rem;
}

/*  Showcase */
.showcase .showcase-text {
  padding: 3rem;
}
.showcase .showcase-img {
  min-height: 20rem;
  background-size: cover;
}
@media (min-width: 768px) {
  .showcase .showcase-img {
    padding: 7rem;
  }
}

/* Case Studies & Testamonials */
.testimonials {
    padding: 3rem;
}
.testimonials .testimonial-item {
  padding: 3rem;
}
.testimonials .testimonial-item img {
  max-width: 30rem;
  box-shadow: 0px 5px 5px 0px #cfd8e6;
}

/* SignUp for details */
.call-to-action {
  position: relative;
  background-color: #343a40;
  background: url("../assets/bg-cta.jpg") no-repeat center center;
  background-size: cover;
  padding-top: 7rem;
  padding-bottom: 7rem;
}
.call-to-action:before {
  content: "";
  position: absolute;
  background-color: #263e4f;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
}
</style>