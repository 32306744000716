import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createBootstrap } from 'bootstrap-vue-next'
import Toast from 'vue-toastification'
import { LoadingPlugin } from 'vue-loading-overlay';
import App from './App.vue'
import AppFooter from './components/AppFooter'
// import SignalRTrips from './components/SignalRTrips';
import router from './router'

// Add the necessary CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import 'vue-toastification/dist/index.css'
import 'vue-loading-overlay/dist/css/index.css';
import './assets/app.scss'

const pinia = createPinia()
const app = createApp(App)
// Default toast options
const options = {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true
};

app
    .use(createBootstrap({components: true, directives: true}))
    .use(Toast, options)
    .use(pinia)
    .use(router)
    .use(LoadingPlugin)
    .directive('scroll', {
        inserted: function(el, binding) {
        let f = function(evt) {
            if (binding.value(evt, el)) {
            window.removeEventListener('scroll', f);
            }
          };
          window.addEventListener('scroll', f);
        }
    })
    .mixin({
        methods: {
        handleScroll: function(evt, el) {
            if (window.scrollY > 100) {
            el.setAttribute(
                'class',
                'navbar navbar-light navbar-expand-lg fixed-top navbar-shrink'
            );
            } else {
            el.setAttribute(
                'class',
                'navbar navbar-light navbar-expand-lg fixed-top'
            );
            }
            //return window.scrollY > 100;
          }
        }
    })
    .component('AppFooter', AppFooter)
    .mount('#app')
