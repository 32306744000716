import { defineStore } from 'pinia'
import { initSubscriber, registerUser, getUser } from '../api/subscribers'
const axios = require('axios')

export const useAuthStore = defineStore('authenticated', {
  state: () => ({
      authenticated: false,
      settings: {}
  }),

  getters: {
    isAuthenticated: state => state.authenticated,
    getSettings: state => state.settings
  },

  actions: {
    changeAuth (value) {
      this.authenticated = value
    },
    setSetting (key, value) {
      this.settings[key] = value
    },
    async initAuth () {
      this.setSetting('URI', process.env.VUE_APP_API || 'api')
      await axios({
          method: 'get',
          // This is how we handle CORS. It gets the value from the 
          // .env file
          url: `${this.settings['URI']}/settings`,
          withCredentials: false
      }).then(res => {
          // Store settings
          if (res && res.data) {
            Object.keys(res.data).forEach(s => {
              window[s] = res.data[s] 
            })
          }
          let apiUrl = initSubscriber()
          if (apiUrl)
            console.info(`[${new Date()}] : Subscriber-API configured: ${apiUrl}`)
          else
            console.error(`[${new Date()}] : Subscriber-API URI missing.`)
      }).catch(err => {
          console.error(err)
      });
    }
  }
})

export const useSubscriberStore = defineStore('subscriber', {
    state: () => ({
        loader: null,
        subscriber: null,        
        subscriptions: {}
    }),
  
    getters: {
      getSubscriber: state => state.subscriber,
      getSubscriptions: state => state.subscriptions
    },
  
    actions: {
      async newSubscriber (value, $loading) {
        this.loader = $loading.show({
            // Optional parameters
            canCancel: true,
            onCancel: this.changeAuth,
            color: 'secondary',
            loader: 'bars',
            isFullPage: true
        })
        if (value)
          await registerUser({ "userid": value.localAccountId })
          .then((res) => { 
            this.subscriber = value
            this.subscriber.subscriberId = res.data.id
            this.loader.hide()
          })
          .catch((err) => {
            this.subscriber = value
            console.error(err)
            throw err
          })
      },
      async setSubscriber (value, $loading) {
        this.loader = $loading ? $loading.show({
          // Optional parameters
          canCancel: true,
          onCancel: this.changeAuth,
          color: 'secondary',
          loader: 'bars',
          isFullPage: true
        }) : null
        if (value)
          await getUser(value.localAccountId)
          .then((res) => {
            this.subscriber = value
            this.subscriber.subscriberId = res.data.id
            this.subscriptions = {}
            if (res && res.data && res.data.apikeys)
              res.data.apikeys.forEach(s => {
                this.subscriptions[s.subscription] = {
                  key: s.primaryKey,
                  state: s.state
                }
              })
            if (this.loader!==null) this.loader.hide() 
          })
          .catch((err) => {
            this.subscriber = value
            console.error(err)
            throw err
          })
      }
    }
  })

  export const useNotificationSystem = defineStore('notifications', {
    state: () => ({
        notificationSystem: {
          options: {
            info: {
              position: 'top-right'
            },
            success: {
              position: 'top-right'
            },
            warning: {
              position: 'top-right'
            },
            error: {
              position: 'top-right'
            }
          }
        }
    }),
  
    getters: {
      notifyOptions: state => state.notificationSystem
    },
  
    actions: {
      setNotifyPosition (position) {
        this.notificationSystem.options.info.position = position
        this.notificationSystem.options.success.position = position
        this.notificationSystem.options.warning.position = position
        this.notificationSystem.options.error.position = position
      }
    }
  })

/* export const useContentLoading = defineStore('loader', {
    state: () => ({
      loader: null,
      options: {
        // Optional parameters
        canCancel: true,
        onCancel: this.resetPlans,
        color: 'secondary',
        loader: 'bars'
      }      
    }),
  
    getters: {
      getLoader: state => state.loader
    },
  
    actions: {
      setLoader (loading, timeout) {
        this.loader = loading.show(this.options)
        if (timeout) {
          setTimeout(() => {
            this.loader.hide()
        }, timeout.isNumber() ? timeout*1000 : 5000)
        }
      },
      hideLoader () {
        if (this.loader && this.loader!==null)
          this.loader.hide()
      }
    }
  })
*/