<template>
  <header class="masthead masthead-custom">
      <div class="container h-100" style="height:100px;">
          <div class="row justify-content-left h-100" style="height:80px;">
              <div class="col-12 col-lg-12 mt-auto" style="margin:0px;margin-top:0px;margin-bottom:0px;height:0px;max-width:100%;">
                  <div class="mx-auto header-content">
                      <h1 class="mb-5 text-black">Plans & Pricing</h1>
                  </div>
                  <h3 class="text-black">Fair plans for personal, commercial and educational use</h3>
              </div>
          </div>
      </div>
  </header>

  <section id="features" class="features" style="padding-top:20px; padding-bottom:10px;">
    <div class="container">
      <div class="card border-grey">
        <div class="card-body" v-if="authenticated">
          <h4 class="card-title">Available Plans {{ user ? 'for '+user.idTokenClaims.name : '' }}</h4>
          <BTable show-empty id="plan-table"
                              responsive
                              striped
                              hover
                              :items="plans"
                              :fields="fields"
                              :current-page="currentPage"
                              :per-page="perPage"
                              >
              <template v-slot:cell(name)="row">
                {{ row.value }}
                <sup><a v-if="row.value.includes('EDU')" href="#fn01" role="doc-noteref">1</a></sup>
              </template>
              <template v-slot:cell(description)="row">{{ row.value }}</template>
              <template v-slot:cell(status)="row">{{ row.value }}</template>
              <template v-slot:cell(invoicing)="row">
                {{ row.value=='none' ? 'Free' : row.value }}
                <sup><a v-if="row.value!=='none'" href="#fn02" role="doc-noteref">2</a></sup>
              </template>
              <template v-slot:cell(services)="plans">
                <BButton size="sm" @click.stop="selectPlan(plans.item); show = !show" class="mr-1">
                  {{ 'Show' }}
                </BButton>
              </template>
              <template v-slot:cell(actions)="plans">
                <BButton size="sm" @click.stop="selectPlan(plans.item); if (plans.item.name.includes('EDU')) { apply = !apply } else { confirm = !confirm };" :disabled="plans.item.state==='subscribed' || !canSubmit" class="mr-1">
                  {{ plans.item.state==='subscribed' ? 'Unsubscribe' : plans.item.name.includes('EDU') ? 'Apply' : 'Subscribe' }}
                </BButton>
            </template>
          </BTable>
          <BRow align-h="end" align-content="center">
            <BCol cols="auto">
              <BPagination 
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="plan-table"
                ></BPagination>
            </BCol>
          </BRow>

          <p class="text-sm-left">
            <ol test-size="xs">
              <li id="fn02" role="doc-footnote"><h6>Value Added Tax</h6></li>
              <small>Prices shown exclude VAT which be added during invoicing according to your geographic location. 
                <br><b>Activation: </b>API key(s) will be sent to your email after payment is received.
                <br>&nbsp;
              </small>
              <li id="fn01" role="doc-footnote"><h6>Educational Subscribers</h6></li>
              <small>Some of our Services & APIs are offered free of charge to interested parties including Secondary Schools, Universities of Applied Sciences, Colleges, or Universities.<br>
                <i>Note: </i>An educational license is only permitted for Research and Education (eg. lectures, seminars, coursework, dissertations). It may only be used upon request and requires the purpose of the initiative as well as the name of the professor / teacher who oversees the engagement.<br></small>
            </ol>
          </p>
        </div>
      </div>
    </div>
  </section>

  <div v-if="show">
    <BModal id="planInformationModal"
              ref="modalRef"
              title="Plan Information"
              header-bg-variant="secondary"
              header-text-variant="light"
              footer-bg-variant="light"
              footer-text-variant="dark"
              hide-backdrop
              ok-only
              v-model="show">
      <BContainer fluid>
        <BFormRow>
          <BCol><em><strong>Plan Name</strong></em></BCol><BCol> {{ selected ? selected.name : 'N/A' }} </BCol>
        </BFormRow>
        <hr />
        <BFormRow>
          <BCol><em><strong>Description</strong></em></BCol><BCol>{{ selected ? selected.description  : 'N/A' }}</BCol>
        </BFormRow>
        <BFormRow>
          <BCol><em><strong>Services</strong></em></BCol><BCol>{{ selected ? selected.services : 'N/A' }}</BCol>
        </BFormRow>
      </BContainer>
    </BModal>
  </div>

  <div v-if="confirm">
    <BModal id="planSubscribeModal"
              ref="confirmRef"
              title="Subscribe to Plan"
              header-bg-variant="secondary"
              header-text-variant="light"
              footer-bg-variant="light"
              footer-text-variant="dark"
              hide-backdrop
              @ok="handleSubscribe"
              v-model="confirm">
      <BContainer fluid>
        <BFormRow>
          <BCol><em><strong>Selected Plan</strong></em></BCol><BCol> {{ selected ? selected.name : 'N/A' }} </BCol>
        </BFormRow>
        <hr />
        <BFormRow>
          <BCol><em><strong>Full Name</strong></em></BCol><BCol> {{ user ? user.name : 'N/A' }} </BCol>
        </BFormRow>
        <BFormRow>
          <BCol><em><strong>Address</strong></em></BCol>
          <BCol> 
            {{ user ? user.idTokenClaims.streetAddress : 'N/A' }}<br>
            {{ user ? user.idTokenClaims.city : 'N/A' }}, {{ user ? user.idTokenClaims.postalCode : 'N/A' }} <br>          
          </BCol>
        </BFormRow>
        <BFormRow>
          <BCol><em><strong>Country</strong></em></BCol><BCol> {{ user ? user.idTokenClaims.country : 'N/A' }} </BCol>
        </BFormRow>
        <BFormRow>
          <BCol><em><strong>eMail</strong></em></BCol><BCol> {{ user ? user.idTokenClaims.emails[0] : 'N/A' }} </BCol>
        </BFormRow>
        <hr />
        <BFormRow>
          <BCol><em><strong>Note: </strong></em>By subscribing, you confirm that you agree to the processing of your personal data by inspired-technologies as described in the Privacy Statement. For further details on how your data is used, stored, and shared, please review <a href='#'>here</a>.</BCol>
        </BFormRow>
      </BContainer>
    </BModal>
  </div>

  <div v-if="apply">
    <BModal id="planApplyModal"
              ref="applyRef"
              title="Apply for Educational Plan"
              header-bg-variant="secondary"
              header-text-variant="light"
              footer-bg-variant="light"
              footer-text-variant="dark"
              hide-backdrop
              @show="resetApply"
              @hidden="resetApply"
              @ok="handleApply"
              v-model="apply">
      <form ref="applyForm" @submit.stop.prevent="validateApply">
        <BFormGroup
          label="Project & Institution"
          label-for="project-input"
        >
          <BFormInput
            id="project-input"
            v-model="applyState.project"
            :state="validateApply('project')"
          ></BFormInput>
          <BFormInvalidFeedback
            id="project-input-live-feedback">
            A project name or description is required and must be at least 10 characters.
          </BFormInvalidFeedback> &nbsp;
          <BFormInput
            id="instituion-input"
            v-model="applyState.institution"
            :state="validateApply('institution')"
          ></BFormInput>
          <BFormInvalidFeedback
            id="project-input-live-feedback">
            The hosting instituion needs to be specified and must be at least 3 characters.
          </BFormInvalidFeedback>
        </BFormGroup> &nbsp;
        <BFormGroup
          label="Name"
          label-for="name-input"
        >
          <BFormInput
            id="name-input"
            v-model="applyState.name"
            :state="validateApply('name')"
          ></BFormInput>
          <BFormInvalidFeedback
            id="name-input-live-feedback">
            Name is required and must be at least 3 characters.
          </BFormInvalidFeedback>
        </BFormGroup>
      </form>
    </BModal>
  </div>

  <!--div>
      <p class="text-black">{{ this.authenticated ? "Authenticated" : "Require Auth" }}</p>
      <p class="text-black">{{ this.user===null ? "No User Data" : this.user.username }}</p>
      <p class="text-black">{{ this.user===null ? "No Subscriber Data" : this.user.subscriberId }}</p>
  </div-->
</template>

<script>
import { ref, inject, reactive, computed } from 'vue'
import { usePlansStore } from '@/stores/subscriptions'
import { useToast } from 'vue-toastification'
import { mapState, mapActions } from 'pinia'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'

export default {
  setup() {
    const show = ref(false)
    const toast = useToast();
    const apply = ref(false)
    const confirm = ref(false)
    const loader = inject('$loading')
    const fullPage = ref(false)
    // handleApply
    const applyState = reactive({
      project: '',
      institution: '',
      name: ''
    })
    const rules = computed(() => {
      const localRules = {
        project: {
            required,
            minLength: minLength(10)
          },          
        institution: {
            required,
            minLength: minLength(3)
          },
        name: {
            required,
            minLength: minLength(3)
          }
        }
        return localRules })
    const v$ = useVuelidate(rules, applyState)
    return { show, toast, apply, confirm, fullPage, loader, applyState, v$ }
  },
  name: 'availablePlans',
  props: { 
    authenticated: Boolean,
    user: Object,
    notify: Object
  },
  data() {
    return {
      message: '',
      html: '<i class="fas fa-cog fa-spin fa-3x fa-fw"></i>',
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'description', label: 'Description' },
        { key: 'services', label: 'Features' },
        { key: 'invoicing', label: 'Pricing' },
        { key: 'state', label: 'State', sortable: true },
        { key: 'actions', label: 'Action' }
      ],
      currentPage: 1,
      perPage: 6,
      pageOptions: [3, 6, 9]
    };
  },
  computed: {
    ...mapState(usePlansStore,['plans','selected']),
    rows() {
        return this.plans.length
      },
    canSubmit() {
      return this.authenticated && this.user.subscriberId!==''
    }
  },
  methods: {
    ...mapActions(usePlansStore,['setPlans','setSelectedPlan','getPlans','subscribeSelected']),
    retrievePlans() {
      this.getPlans(this.user ? this.user.localAccountId : 'all', this.loader)
      .catch(err => {
          this.toast.error(
            err.response ? err.response : err.message ? err.message : err,
            this.notify.error
          );
      })
    },
    selectPlan(item) {
      this.setSelectedPlan(item);
    },
    resetApply() {
      this.applyState = {
        project: null,
        institution: null,
        name: null
      }
      this.$nextTick(() => {
        this.v$.$reset();
      });
    },
    async handleApply() {
      let result = await this.v$.$validate()
      if (!result) {
        this.apply = !this.apply
        return false
      }
      console.debug(`[${new Date(Date.now()).toString()}] : Info - ${this.user.username} applying to ${this.selected.name} with ${JSON.stringify(this.applyState)}`)
      this.$nextTick(() => {
        this.resetApply();
        this.v$.$reset();
      })
    },
    validateApply(prop) {
      const { $dirty, $error } = this.v$[prop]
      return $dirty ? !$error : null
    },
    async handleSubscribe() {
      if (!this.user || !this.user.subscriberId)
        return      
      console.debug(`[${new Date(Date.now()).toString()}] : Info - ${this.user.username} subscribing to ${this.selected.name}`)
      await this.subscribeSelected(this.user.localAccountId, this.loader)
      .then(() => {
        this.$router.push('/subscriptions');
      })
      .catch(err => {
          this.toast.error(
            err.response ? err.response : err.message ? err.message : err,
            this.notify.error
          );
      })
    }
  },
  mounted() {
    this.retrievePlans()
  }
}
</script>
