import { defineStore } from 'pinia'
import { getAvailablePlans, 
         subscribeToPlan,
         listSubscriptions,
         terminateSubscription
} from '../api/subscribers'

export const useDescriptionsStore = defineStore('descriptions', {
    state: () => ({
        services: [],
        selected: null
    }),

    getters: {
    },

    actions: {
        setServices (value) {
            this.services = value
            this.selected = null
        },
        resetServices () {
            this.services = []
            this.selected = null
        },
        setRequestedService (value) {
            this.selected = value
        },
        async getServiceDescriptions (user, $loading) {
            const loader = $loading.show({
                // Optional parameters
                canCancel: true,
                onCancel: this.resetServices,
                color: 'secondary',
                loader: 'bars'
            });
            let loading = true
            try {
                setTimeout(() => {
                    if (loading)
                        loader.hide()
                        loading=false
                    }, 10000)                
                /* await getActiveServices(user)
                .then((res) => {
                    this.setServices(res && res.data ? res.data : [])
                }) */
                this.setServices([])
            } finally {
                loader.hide()
                loading=false
            }
        }
    }
})

export const usePlansStore = defineStore('plans', {
    state: () => ({
        plans: [],
        selected: null
    }),

    getters: {
        // getPlans: state => state.plans,
        // getSelectedPlan: state => state.selected
    },

    actions: {
        setPlans (value) {
            this.plans = value
            this.selected = null
        },
        resetPlans () {
            this.plans = []
            this.selected = null
        },
        setSelectedPlan (value) {
            this.selected = value
        },
        async getPlans (user, $loading) {
            const loader = $loading.show({
                // Optional parameters
                canCancel: true,
                onCancel: this.resetPlans,
                color: 'secondary',
                loader: 'bars'
            });
            let loading = true
            try {
                setTimeout(() => {
                    if (loading)
                        loader.hide()
                        loading=false
                    }, 10000)                
                await getAvailablePlans(user)
                .then((res) => {
                    this.setPlans(res && res.data ? res.data : [])
                })
            } finally {
                loader.hide()
                loading=false
            }
        },
        async subscribeSelected (user, $loading) {
            const loader = $loading.show({
                // Optional parameters
                canCancel: true,
                onCancel: this.resetPlans,
                color: 'secondary',
                loader: 'bars'
            });
            let loading = true
            try {
                setTimeout(() => {
                    if (loading)
                        loader.hide()
                        loading=false
                    }, 60000)                
                await subscribeToPlan(user, this.selected.name)
                .then((res) => {
                    if (res.status===201)
                        // move to subscriptions page
                        console.debug(`[${new Date(Date.now()).toString()}] : Info - created new subscription ${res.data} for ${user}`)
                    else
                        this.getPlans(user, $loading)
                    this.selected = null
                })
            } finally {
                loader.hide()
                loading=false
            }
        }
    }
})

export const useServicesStore = defineStore('services', {
    state: () => ({
        services: [],
        subscription: null
    }),

    getters: {},

    actions: {
        setServices (value) {
            this.services = value
            this.subscription = null
        },
        resetServices () {
            this.services = []
            this.subscription = null
        },
        setSubscription (value) {
            this.subscription = value
        },
        async getServices (user, subscriptions, $loading) {
            const loader = $loading.show({
                // Optional parameters
                canCancel: true,
                onCancel: this.resetServices,
                color: 'secondary',
                loader: 'bars'
            });
            let loading = true
            try {
                setTimeout(() => {
                    if (loading)
                        loader.hide()
                        loading=false
                    }, 10000)                
                await listSubscriptions(user)
                .then((res) => {
                    res.forEach(s => {
                        s.apim = subscriptions[s.id]
                    })
                    this.setServices(res ? res : [])
                })
            } finally {
                loader.hide()
                loading=false
            }
        },
        async unsubscribeSelected (user, date, $loading) {
            const loader = $loading.show({
                // Optional parameters
                canCancel: true,
                onCancel: this.resetServices,
                color: 'secondary',
                loader: 'bars'
            });
            let loading = true
            try {
                setTimeout(() => {
                    if (loading)
                        loader.hide()
                        loading=false
                    }, 60000)                
                await terminateSubscription(this.selected.id, date ? date : new Date(Date.now()))
                .then((res) => {
                    if (res.status===200)
                        // reload subscriptions
                        console.debug(`[${new Date(Date.now()).toString()}] : Info - terminated subscription ${res.data} iniiated by ${user}`)
                    else
                        this.getServices(user, $loading)
                    this.selected = null
                })
            } finally {
                loader.hide()
                loading=false
            }
        }
    }
})