<template>
    <header class="masthead masthead-custom">
        <div class="container h-100" style="height:100px;">
            <div class="row justify-content-left h-100" style="height:80px;">
                <div class="col-12 col-lg-12 mt-auto" style="margin:0px;margin-top:0px;margin-bottom:0px;height:0px;max-width:100%;">
                    <div class="mx-auto header-content">
                        <h1 class="mb-5 text-black">{{ $route.params.service==='all' || $route.params.service==='' ? 'Services Overview' : $route.params.service }}</h1>
                    </div>
                    <h3 class="text-black">{{ $route.params.service==='all' || $route.params.service==='' ? '' : 'Service Description' }}</h3>
                </div>
            </div>
        </div>
    </header>   

  <section id="overview" v-if="this.selected==='all'" class="features" style="padding-top:20px; padding-bottom:10px;">
    <div class="container">
        <div class="card-body">
          <!--b-table show-empty
                              responsive
                              striped
                              hover
                              :items="services"
                              :fields="fields"
                              :current-page="currentPage"
                              :per-page="perPage"
                            >
              <template v-slot:cell(service)="row">{{ row.value }}</template>
          </b-table-->
        </div>
    </div>
  </section>

  <section id="overview" v-if="this.selected!=='all'" class="features" style="padding-top:20px; padding-bottom:10px;">
    <div class="container">
        <div class="card-body">
            <h4 class="card-title">{{ this.selected }}</h4>
        </div>
    </div>
  </section>

</template>

<script>
import { ref, inject } from 'vue'
import { useDescriptionsStore } from '@/stores/subscriptions'
import { mapState, mapActions } from 'pinia'
import { useToast } from 'vue-toastification'

export default {
  setup() {
    const show = ref(false)
    const key = ref(false)
    const toast = useToast();
    const loader = inject('$loading')
    const fullPage = ref(false)
    return { show, key, toast, fullPage, loader }
  },
  name: 'serviceDescription',
  props: { 
    authenticated: Boolean,
    user: Object,
    notify: Object
  },
  data() {
    return {
      message: '',
      html: '<i class="fas fa-cog fa-spin fa-3x fa-fw"></i>',
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'description', label: 'Description' },
        { key: 'state', label: 'State', sortable: true },
        { key: 'actions', label: 'Action' }
      ],
      currentPage: 1,
      perPage: 6,
      pageOptions: [3, 6, 9]
    }
  },
  computed: {
    ...mapState(useDescriptionsStore,['descriptions','selected']),
  },
  methods: {
    ...mapActions(useDescriptionsStore,['setServices','setRequestedService','getServiceDescriptions']),
  },
  mounted() {
    console.log(this.$route.params)
    this.setRequestedService(this.$route.params.service)
  }
}
</script>

<!--script>
import { ref, inject } from 'vue'
// import { useSubscriberStore } from '@/stores/common'
import { useDescriptionsStore } from '@/stores/subscriptions'
import { mapState, mapActions } from 'pinia'
import { useToast } from 'vue-toastification'

export default {
  setup() {
    const show = ref(false)
    const key = ref(false)
    const toast = useToast();
    const loader = inject('$loading')
    const fullPage = ref(false)
    return { show, key, toast, fullPage, loader }
  },
  name: 'serviceDescription',
  props: { 
    authenticated: Boolean,
    user: Object,
    notify: Object
  },
  data() {
    return {
      message: '',
      html: '<i class="fas fa-cog fa-spin fa-3x fa-fw"></i>',
      service: this.services[this.selected]
    };
  },
  computed: {
    ...mapState(useDescriptionsStore,['services','selected']),
    canSubmit() {
      return this.authenticated && this.user.subscriberId!==''
    }
  },
  methods: {
    ...mapActions(useDescriptionsStore,['setServices','setRequestedService','getServiceDescriptions']),
    retrieveAll() {
      this.getServiceDescriptions('all', this.loader)
      .catch(err => {
          this.toast.error(
            err.response ? err.response : err.message ? err.message : err,
            this.notify.error
          );
      })
    },
  },
  mounted() {
    this.retrieveAll()
    this.$route(
        (params) => this.setRequestedService(params.service)
    )
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        // react to route changes...
        console.log(`Moving from ${previousParams}`)
        console.log(`Moving to ${previousParams}`)
        // this.setRequestedService(toParams.service)
      }
    )
  }
}  
</script-->