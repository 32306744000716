// import { checkResponse, post, get, put } from '@/utils/http';
import { checkResponse, post, get } from '@/utils/http';

let apiUrl
let apiKey
let apiCode

export function initSubscriber() {
  apiUrl = window.apiSubscriberUrl ? window.apiSubscriberUrl.includes('http') ? window.apiSubscriberUrl : window.apiBaseUrl + window.apiSubscriberUrl : undefined;
  apiKey = window.apiKey;
  apiCode = window.apiSubscriberKey ? { code: window.apiSubscriberKey } : {}
  return apiUrl
}

// POST methods
export function registerUser(user) {
    return post(`${apiUrl}/users`, user, apiKey).then(checkResponse);
  }

export function subscribeToPlan(user, plan) {
    return post(`${apiUrl}/subscriptions/${user}/${plan}`, user, apiKey).then(checkResponse);
  }

// PUT methods
export function terminateSubscription(id, date) {
  return new Error(`NOT_IMPLEMENTED (${id}:${date})`)
  /* return put(`${apiUrl}/subscriptions/${id}/terminate`, {
      initiated: (new Date(Date.now())).toISOString(), 
      requested: date.toISOString() 
    }, apiKey)
    .then(checkResponse); */
}

// GET methods
export function getUser(user) {
  return get(`${apiUrl}/users/${user}/data`, apiCode, apiKey).then(checkResponse);
}

export async function getAvailablePlans(user) {
  let result = await get(`${apiUrl}/plans/${user ? user : 'all'}`, apiCode, apiKey).then(checkResponse);

  return { 
        status: result ? result.status : 500,
        statusText: result ? result.statusText : 'Internal Error',
        data: result && result.data ? result.data.map(p => { return {
        name: p.plan,
        description: p.description,
        services: p.services.filter(s => s.name.toLowerCase()!=='subscriptions').map(s => { return s.name }).join(', '),
        invoicing: p.params.invoicing!=='none' ? `${p.params.symbol} ${p.params.price} ${p.params.invoicing==='monthly' ? 'p.m.' : p.params.invoicing==='annual' ? 'p.a.' : p.params.invoicing } ` : p.params.invoicing ,
        state: p.status,
        actions: null
    }}).filter(s => s.services.length!==0) : [] }
}

export async function listSubscriptions(user) {
  let services = []
  if (user) {
    let result = await get(`${apiUrl}/users/${user}/subscriptions`, apiCode, apiKey).then(checkResponse);
    if (result) result.data.forEach(sub => {
      sub.services.filter(s => s.service.toLowerCase()!=='subscriptions').forEach(s => {
        services.push({
          id: sub.id,
          service: s.service,
          description: s.description,
          license: s.license, 
          plan: sub.plan, 
          state: sub.status,
          details: s.details,  
          actions: sub.valid
        })
      })
    })

  }
  return services
}