import { createWebHistory, createRouter } from 'vue-router';

import Home from '@/views/LandingPage.vue'
import NoAuth from '@/views/NoAuth.vue'
import NotFound from '@/views/NotFound.vue'
import Subscriptions from '@/views/SubscriptionsPage.vue'
import Plans from '@/views/PlansPage.vue';
import Service from '@/views/ServicePage.vue';
// import User from '@/views/UserProfile.vue';
import { requireAuth } from '@/utils/auth';

const routes = [
    {
        path: '/',
        name: 'landing',
        component: Home
    },
    {   path: '/home', 
        redirect: '/' 
    },
    {
        path: '/plans',
        name: 'plans',
        component: Plans,
        beforeEnter: requireAuth
    },
    {
        path: '/subscriptions',
        name: 'subscriptions',
        component: Subscriptions,
        beforeEnter: requireAuth
    },
    { 
        path: '/services', 
        redirect: '/services/all' 
    },
    {
        path: '/services/:service',
        name: 'service',
        component: Service
    },
    {
        path: '/no-auth',
        name: 'noaccess',
        component: NoAuth
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'notfound',
      component: NotFound
    }

    /* {
      path: '/user/:name',  // <-- notice the colon
      name: 'user',
      component: User,
      props: true
    } */
  ];

const router = createRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition;
        }
        if (to.hash) {
          return { selector: to.hash };
        }
        return { x: 0, y: 0 };
      }
  });
  
export default router;