<template>
  <div>
    <header class="masthead masthead-custom">
        <div class="container h-100" style="height:155px;">
            <div class="row justify-content-center h-100" style="height:155px;">
                <div class="col-12 col-lg-7 mt-auto" style="margin:0px;margin-top:0px;margin-bottom:0px;height:0px;max-width:100%;">
                    <div class="mx-auto header-content text-center">
                      <h1 class="mb-5 text-danger center">404&nbsp;&nbsp;</h1>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="features" class="features" style="padding-top:60px;">
        <div class="container">
            <div class="section-heading text-center" style="margin-bottom:50px;">
                <h2>Page not found!</h2>
                <p class="text-muted">&nbsp;</p>
                <p>Re-view our currently <router-link to="/services/all">active services</router-link> to get started!</p>
                <hr />
                <p class="text-muted">If you don't have an account, choose the <strong>sign up</strong> link below the login form to create a new account.</p>
                <p class="text-muted"><strong>Please note: </strong>the login form displays in a popup window. Please disable your browser's popup blocker for this site.</p>
            </div>
        </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'notFound',
};
</script>