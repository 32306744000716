<template>
  <div>
    <nav v-scroll="handleScroll" class="navbar navbar-dark navbar-expand-lg fixed-top" id="mainNav">
      <div id="nav" class="container">
          <router-link :to="{ name: 'landing' }" class="navbar-brand text-white">
          Inspired Cloud Native Services 
          </router-link>
          <button class="navbar-toggler float-right" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive" aria-controls="navbarNavAltMarkup"
            aria-expanded="false" aria-label="Toggle navigation"><!--i class="fa fa-bars"></i-->
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="nav navbar-nav ml-auto">
              <li class="nav-item" role="presentation">
                <router-link :to="{ name: 'plans' }" class="nav-link text-white">Pricing</router-link>
              </li>
              <li class="nav-item" role="presentation">
                <router-link :to="{ name: 'subscriptions' }" class="nav-link text-white">My Subscriptions</router-link>
              </li>
              <!--li class="nav-item" role="presentation">
                <router-link :to="{ name: 'drivers' }" class="nav-link">Drivers</router-link>
              </li-->
              <li class="nav-item" role="presentation" v-if=this.authenticated>
                <a href="javascript:void(0)" class="nav-link text-white" @click.stop="logout()">Logout</a>
              </li>
              <li class="nav-item" role="presentation" v-else>
                <a href="javascript:void(0)" class="nav-link text-white" @click.stop="login()">Login</a>
              </li>
            </ul>
          </div>
      </div>
    </nav>
    <router-view 
      :authenticated=this.authenticated :user=this.subscriber :notify=this.notify
    ></router-view>
    <AppFooter />
  </div>
</template>  

<script>
import { inject, onBeforeMount } from 'vue'
import { newAuth } from '@/utils/http'
import { useAuthStore, useNotificationSystem, useSubscriberStore } from '@/stores/common'
import { useToast } from 'vue-toastification'
import { mapState, mapActions } from 'pinia'
let auth

export default {
  setup() {
    onBeforeMount(async () => {
      // await fetch(!process.env.VUE_APP_API.includes('code') ? `${process.env.VUE_APP_API || 'api'}/settings` : process.env.VUE_APP_API.replace('/api?code', '/api/settings?code'))
      await fetch(`${process.env.VUE_APP_API || 'api'}/settings`)
      .then(response => response.json())
      .then(async (settings) => {
        if (settings) {
          Object.keys(settings).forEach(s => {
            window[s] = settings[s] 
          })
          auth = newAuth();
          await auth.init();
        }
      })
    })
    const toast = useToast();
    const notifications = useNotificationSystem();
    const loading = inject('$loading')
    return { toast, notify: notifications.notifyOptions.options, loading }
  },
  name: 'App',
  data() {
    return {
      auth
    }
  },
  computed: {
    ...mapState(useSubscriberStore,['subscriber','getSubscriberAPI']),
    ...mapState(useAuthStore,['authenticated']),
    ...mapState(useNotificationSystem,['notifications'])
  },
  methods: {
    ...mapActions(useSubscriberStore,['initSubscriberStore','setSubscriber','newSubscriber']),
    ...mapActions(useAuthStore,['initAuth','changeAuth']),
    ...mapActions(useNotificationSystem,['setNotifyOptions']),
    login() {
      auth.login().then(
        user => {
          if (user) {
            if (user.idTokenClaims.newUser)
              this.newSubscriber(user, this.loading)
              .then(() => {
                this.toast.success(`Welcome ${user.idTokenClaims.name}!`, this.notify.success)
              })
              .catch((err) => {
                if (err.code==='ERR_NETWORK')
                  this.toast.error(err.message, this.notify.error)
              })
            else
              this.setSubscriber(user, this.loading)
              .then(() => {
                this.toast.success(`${user.idTokenClaims.name} logged in!`, this.notify.success)
              })
              .catch((err) => {
                if (err.code==='ERR_NETWORK')
                  this.toast.error(err.message, this.notify.error)
              })
            this.changeAuth(true);
          } else {
            this.setSubscriber(null);
            this.changeAuth(false);
          }
        },
        () => {
          this.setSubscriber(null);
          this.changeAuth(false);
        }
      );
    },
    logout() {
      if (confirm('Are you sure you wish to log out?')) {
        auth.logout().then(() => {
          this.setSubscriber(null);
          this.changeAuth(false);
          this.$router.push('/');
          this.toast.info('Logged out!')
        });
      }
    },
  },
  async mounted() {
    await this.initAuth() 
    .then(() => {
      console.log(`[${new Date()}] : Authentication initialized.`)
    })
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,300italic,400italic,700italic");
#mainNav .navbar-nav > li > a.router-link-exact-active {
  color: #ee6c4d !important;
  background-color: transparent;
}
header.masthead {
  position: relative;
  background-color: #3d5a80;
  background: url("./assets/bg-masthead.jpg") no-repeat center center;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 10rem;
}
header.masthead:before {
  content: "";
  position: absolute;
  background-color: #3d5a80;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
}
header.masthead h1, header.masthead .h1 {
  font-size: 2rem;
}
@media (min-width: 768px) {
  header.masthead {
    padding-top: 8rem;
    padding-bottom: 12rem;
  }
  header.masthead h1, header.masthead .h1 {
    font-size: 3rem;
  }
}
footer.footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>