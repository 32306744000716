<template>
  <!-- Footer without Social Media -->
  <footer class="footer sticky-bottom bg-light">
      <div class="container">
          <div class="row">
              <div class="col-lg-6 h-100 text-center text-lg-start my-auto">
                  <p class="text-muted mb-4 mb-lg-0">&copy; 2024, Inspired Technologies GmbH.<br>&nbsp; All Rights Reserved.</p>
              </div>
              <div class="col-lg-6 h-100 text-center text-lg-end my-auto">
                <ul class="list-inline mb-2">
                      <li class="list-inline-item"><a href="#!">About</a></li>
                      <li class="list-inline-item">⋅</li>
                      <li class="list-inline-item"><a href="#!">Contact</a></li>
                      <li class="list-inline-item">⋅</li>
                      <li class="list-inline-item"><a href="#!">Terms of Use</a></li>
                      <li class="list-inline-item">⋅</li>
                      <li class="list-inline-item"><a href="#!">Privacy Policy</a></li>
                  </ul>
              </div>
          </div>
      </div>
  </footer>
</template>


<!-- Footer with Social Media>
<template>
  <footer class="footer bg-light">
      <div class="container">
          <div class="row">
              <div class="col-lg-6 h-100 text-center text-lg-start my-auto">
                  <ul class="list-inline mb-2">
                      <li class="list-inline-item"><a href="#!">About</a></li>
                      <li class="list-inline-item">⋅</li>
                      <li class="list-inline-item"><a href="#!">Contact</a></li>
                      <li class="list-inline-item">⋅</li>
                      <li class="list-inline-item"><a href="#!">Terms of Use</a></li>
                      <li class="list-inline-item">⋅</li>
                      <li class="list-inline-item"><a href="#!">Privacy Policy</a></li>
                  </ul>
                  <p class="text-muted small mb-4 mb-lg-0">&copy; 2023, Inspired Technologies GmbH. All Rights Reserved.</p>
              </div>
              <div class="col-lg-6 h-100 text-center text-lg-end my-auto">
                  <ul class="list-inline mb-0">
                      <li class="list-inline-item me-4">
                          <a href="#!"><i class="bi-facebook fs-3"></i></a>
                      </li>
                      <li class="list-inline-item me-4">
                          <a href="#!"><i class="bi-twitter fs-3"></i></a>
                      </li>
                      <li class="list-inline-item">
                          <a href="#!"><i class="bi-instagram fs-3"></i></a>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </footer>
</template-->

<!--style scoped>
footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 50px;
}
</style-->