<template>
  <div>
    <header class="masthead masthead-custom">
        <div class="container h-100" style="height:155px;">
            <div class="row justify-content-center h-100" style="height:155px;">
                <div class="col-12 col-lg-7 mt-auto" style="margin:0px;margin-top:0px;margin-bottom:0px;height:0px;max-width:100%;">
                    <div class="mx-auto header-content text-center">
                      <h1 class="mb-5 text-secondary">&nbsp;</h1>
                      <h1 class="mb-5 text-danger center">NO ACCESS&nbsp;&nbsp;</h1>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="features" class="features" style="padding-top:60px;">
        <div class="container">
            <div class="section-heading text-center" style="margin-bottom:50px;">
                <h2>You're not signed in!</h2>
                <p class="text-muted">&nbsp;</p>
                <p>Please <a href="javascript:void(0)" class="btn btn-danger action-button" role="button" @click="login()">log in</a> to get started!</p>
                <hr />
                <p class="text-muted">If you don't have an account, choose the <strong>sign up</strong> link below the login form to create a new account.</p>
                <p class="text-muted"><strong>Please note: </strong>the login form displays in a popup window. Please disable your browser's popup blocker for this site.</p>
            </div>
        </div>
    </section>
  </div>
</template>

<script>
import { onBeforeMount } from 'vue'
import { Authentication } from '@/utils/auth'
let auth
import { useAuthStore, useSubscriberStore } from '@/stores/common'
import { mapActions } from 'pinia'
export default {
  setup() {
    onBeforeMount(async () => {
      auth = new Authentication()
    })
  },
  name: 'noAccess',
  props: { authenticated: Boolean },
  computed: {},
  methods: {
    ...mapActions(useSubscriberStore,['newSubscriber','setSubscriber']),
    ...mapActions(useAuthStore,['changeAuth']),
    login() {
      auth.login().then(
        user => {
          if (user) {
            if (user.idTokenClaims.newUser)
              this.newSubscriber(user)
            else
              this.setSubscriber(user)
            this.changeAuth(true);
            // let redirect = this.$route.query.redirect;
            // if (redirect) {
            //   this.$router.push(redirect);
            // } else {
            //   this.$router.push('/');
            // }
          } else {
            this.setSubscriber(null);
            this.changeAuth(false);
            // this.$router.push('/');
          }
          this.$router.push('/');
        },
        () => {
          this.setSubscriber(null);
          this.changeAuth(false);
          this.$router.push('/');
        }
      );
    }
  }
};
</script>
